/* istanbul ignore file */
import { IDropdownOption } from '@ui-modules/types';

export declare type SortOrder = 'descend' | 'ascend' | null;

export enum AuthProviderType {
  azure = 'is_azure_user',
  b2c = 'is_b2c_user',
  google = 'is_google_user',
  nonFederated = 'is_non_federated_user',
}

export enum FuelType {
  Petrol = 1,
  Diesel,
  Electric,
}

export interface ApiList<T> {
  count: number;
  next: string | null;
  previous: string | null;
  results: T[];
}

export interface AvailableLocationsApi {
  city: {
    id: number;
    name: string;
  };
  country: {
    id: number;
    iso_code: string;
    name: string;
  };
  emergency_level: number | null;
  id: number;
  name: string;
}

export interface AvailableLocations {
  city: string;
  cityId: string;
  country: string;
  emergency: number | null;
  id: number;
  isoCode: string;
  facilityName: string;
}

export interface AgencyApi {
  complete_name: string;
  email: string;
  logo_url?: string;
  short_name: string;
  uuid: string;
}

export interface AgencyType {
  completeName: string;
  email: string;
  id: string;
  logo?: string;
  shortName: string;
}

export interface Crumb {
  bgImg?: string;
  Component: any;
  data?: any;
  name?: string;
  path: string;
  showNav?: boolean;
}

export interface IAvailableFacilitiesApi {
  city: {
    id: number;
    name: string;
  };
  country: {
    id: number;
    name: string;
  };
  id: number;
  managed_agencies: {
    id: number;
    short_name: string;
    uuid: string;
  }[];
  name: string;
}

export interface IAvailableFacilities {
  agencies: {
    label: string;
    value: string;
  }[];
  city: {
    label: string;
    value: string;
  };
  country: {
    label: string;
    value: string;
  };
  facilityName: string;
  id: string;
}

export interface IFacilityResource {
  category?: string;
  description: string;
  file: string;
  link: string;
}

export interface IFacilityApi {
  agency: string;
  agency_id: string;
  asp_avg_rating: number;
  avg_rating: number;
  category_name: string;
  city_name: string;
  cost_recovery_by_vehicle_type: boolean;
  country_code: string;
  country_name: string;
  email: string;
  emergency_level: number;
  enabled_feedbacks: boolean;
  fleet_management_url: string;
  hbh_digital_office_url: string;
  is_active: boolean;
  is_quest: boolean;
  location_file_categories: Array<'documents' | 'manuals' | 'trainings'>;
  name: string;
  shared_with_agencies: IDropdownOption[];
  uuid: string;
  vehicle_tracking_system: string;
}

export interface IFacility {
  agency: string;
  agencyId: string;
  aspAvgRating?: number | null;
  avgRating?: number | null;
  categoryName: string;
  city: string;
  country: string;
  countryCode: string;
  costRecoveryByVehicleType?: boolean;
  email: string;
  emergencyLevel?: number;
  enabledFeedbacks?: boolean;
  fleetManagementUrl?: string;
  hbhDigitalOfficeUrl?: string;
  facilityName: string;
  isActive: boolean;
  isQuest: boolean;
  locationFileCategories: Array<'documents' | 'manuals' | 'trainings'>;
  sharedWithAgencies: IDropdownOption[];
  uuid: string;
  vehicleTrackingSystem?: string;
}

export interface FeatureFlags {
  flags: { [name: string]: { is_active: boolean; last_modified: string } };
  samples: { [name: string]: { is_active: boolean; last_modified: string } };
  switches: { [name: string]: { is_active: boolean; last_modified?: string; note?: string } };
}

export interface IFile {
  file: File;
  name: string;
}

export interface IRadio {
  label: string;
  value: string;
}

export interface IUserInfoApi {
  email: string;
  first_name: string;
  indexno?: string;
  is_dfp?: string;
  is_driver?: boolean;
  last_name: string;
  phone_number: string;
  requesting_unit: string;
  supervisor_email: string;
}

export interface IUserInfo {
  email: string;
  firstName: string;
  index: string;
  isDfp?: boolean;
  isDriver?: boolean;
  lastName: string;
  phoneNumber: string;
  requestingUnit: string;
  supervisorEmail: string;
}

export interface OptionType {
  label: string;
  value: string;
}

export interface SchemaChoiceApi {
  display_name: string;
  value: number | string;
  locations?: SchemaChoiceApi[];
}
