/* istanbul ignore file */
export const TRANSLATIONS_EN = {
  booking: {
    accepted: 'Accepted',
    bookingRequest: 'Booking Request',
    cancelledByFP: 'Cancelled by FP',
    finished: 'Finished',
    msgBookingAccepted: 'Booking has already been accepted',
    msgBookingRejected: 'Booking was rejected',
    notProcessed: 'Not Processed',
    ongoing: 'Ongoing',
    pickup: 'Pickup',
    process: 'Process',
    processed: 'Processed',
    refCode: 'Ref code',
    rejected: 'Rejected',
    request: 'Request Date',
    time: 'Time',
    userCancelled: 'User Cancelled',
  },
  bookingDetails: {
    bookingRequestor: 'Booking requestor',
    btnConfirmAccept: 'Confirm Accepting',
    btnReject: 'Reject',
    btnShowPreview: 'Show Preview',
    chooseTime: 'Choose time',
    dropoffAddress: 'Drop-off Address',
    dropoffAt: 'Dropoff At',
    dropoffDate: 'Drop-off Date',
    dropoffLocation: 'Drop-off Location',
    dropoffLocExtra: 'Drop-off (Additional)',
    dropoffTime: 'Drop-off Time',
    dropoffTown: 'Drop-off Town',
    errorMsg: 'Booking Details were not found',
    estimatedByGoogle: 'Estimated by Google',
    leg: 'Leg',
    msgConflictDrives: 'has other drives for this time period. Please select other',
    msgConflictMulti: 'has other drives for this time period. Please select another driver',
    msgDriveAccepted: 'Drive has already been accepted',
    msgInvalidTime: 'The drop-off time cannot be earlier or the same as the pick-up time',
    msgUpdateBooking: 'Booking was successfully updated',
    outward: 'Outward',
    outwardTrip: 'Outward trip',
    pickupAddress: 'Pick-up Address',
    pickupAt: 'Pickup at',
    pickupDate: 'Pick-up Date',
    pickupLocation: 'Pick-up Location',
    pickupLocExtra: 'Pick-up (Additional)',
    pickupTime: 'Pick-up Time',
    pickupTown: 'Pick-up Town',
    processTrip: 'Process trip',
    return: 'Return',
    returnTime: 'Return Time',
    returnTrip: 'Return trip',
    selected: 'Selected',
    showBookingRequestorDetails: 'Show booking requestor details',
    signatureCode: 'Signature code',
    title: 'Booking details',
    viewBookingDetails: 'View Booking Details',
  },
  bookingForm: {
    agentDetails: 'Agent Details',
    attachment: 'Attachment',
    attachments: 'Add additional attachments (optional)',
    bookerDetails: 'Booker Details',
    bookingPassengerDetails: 'Booking Passenger Details',
    bookingRecap: 'Booking recap',
    bookingType: 'Booking type',
    btnConfirmBooking: 'Confirm booking',
    btnGoBack: 'Go back to edit',
    btnHomePage: 'Home Page',
    details: 'Details',
    drive: 'Drive',
    extraInformation: 'Extra information',
    managerEmail: 'E-mail of your supervisor',
    msgCopySentToEmail: 'You will receive a copy of your request at',
    msgNoRoutes: 'No routes found for this date',
    msgSuccess: 'Your request has been sent successfully',
    pax: 'Number of passengers',
    requestYourBooking: 'Request your booking',
    recurringDrive: 'Recurring drive',
    routeDetails: 'Route details',
    selectTripType: 'Select trip type',
    tabColleague: 'This booking is for someone else',
    tabSelf: 'This booking is for me',
    title: 'Booking for',
    tooltipAddress: 'No address found by Google, please further specify your address below',
    tooltipGoogleLocation:
      'Type and select the desired location with Google Maps. If necessary, specify your exact address in the additional address field in the form',
    traveller: 'Traveller',
    travellerDetails: 'Traveller Details',
    type: 'Type',
    your: 'Your',
  },
  bookingList: {
    bookingForwarded: 'Booking forwarded',
    btnExport: 'Select range',
    btnExportCurrentView: 'Current View',
    errorMsg: 'No booking for this period',
    filterLabelPax: 'Filter by Pax Agency',
    filterLabelStates: 'Filter by Status',
    inputSearch: 'Search by email or ref code',
    modalTitle: 'Choose the export range by booking request',
    msgModalExport: 'You can download current view',
    optionalAttachments: 'with optional attachments',
    rejectComment: 'Reject Comment',
    rejectModalTitle: 'This booking route will be rejected',
    selectedShuttle: 'shuttle bookings selected',
    title: 'Booking to process for',
  },
  checklist: {
    checkInformation: 'Check information',
    remarksFromManager: 'Remarks from Transportation Manager',
    statusChecklist: 'Status checklist',
    unknownDriver: 'Unknown driver',
  },
  common: {
    agencies: 'Agencies',
    agency: 'Agency',
    agencyName: 'Agency name',
    agencyPax: 'Agency Pax',
    agencySelectLabel: 'Your are visiting this as',
    airport: 'Airport',
    allAgencies: 'All Agencies',
    allMonths: 'All months',
    allStatuses: 'All Statuses',
    allYears: 'All years',
    amount: 'Amount',
    armoured: 'Armoured',
    averageRating: 'Average Rating',
    booking: 'Booking',
    btnApply: 'Apply',
    btnBack: 'Back',
    btnBackToList: 'Back to List',
    btnBackToPlanner: 'Back to planner',
    btnCalculateNow: 'Calculate Now',
    btnCancel: 'Cancel',
    btnCancelDrive: 'Cancel drive',
    btnChange: 'Change',
    btnClearAll: 'Clear all',
    btnConfirm: 'Confirm',
    btnConfirmAndEdit: 'Confirm and Edit',
    btnConfirmChanges: 'Confirm Changes',
    btnConfirmDates: 'Confirm Dates',
    btnDone: 'Done',
    btnDownload: 'Download',
    btnDownloadInvoice: 'Download Invoice',
    btnEdit: 'Edit',
    btnExport: 'Export',
    btnExportData: 'Export Data',
    btnNext: 'Next',
    btnResetFilters: 'Reset Filters',
    btnRevoke: 'Revoke',
    btnSave: 'Save',
    btnVerify: 'Verify',
    btnView: 'View',
    budgetCode: 'Budget Code',
    carpooled: 'Carpooled',
    city: 'City',
    click: 'Click',
    costRecoveryReport: 'Cost Recovery Report',
    country: 'Country',
    currency: 'Currency',
    customerSatisfaction: 'Customer Satisfaction',
    daily: 'Daily',
    delete: 'Delete',
    description: 'Description',
    disable: 'Disable',
    documents: 'Documents',
    downloadCSV: 'Download CSV',
    driver: 'Driver',
    drivers: 'Drivers',
    dropoffTimeAirport: 'Flight time (arrival)',
    duration: 'Duration',
    editDrive: 'Edit Drive',
    email: 'Email',
    emailAddress: 'Email Address',
    emptyList: 'Empty list',
    errorMsgDefault: 'Something went wrong',
    errorMsgDateRange: 'The specified date range is not valid',
    errorMsgGps: 'Impossible to connect!',
    fieldRequired: 'This field is required',
    filters: 'Filters',
    financials: 'Financials',
    firstName: 'First Name',
    fleetManagement: 'Fleet Management',
    flightArrivalDepartureTime: 'Flight arrival time',
    flightNumber: 'Flight number',
    from: 'from',
    fromIncluded: 'From (included)',
    greenFund: 'Green fund',
    indexNumber: 'Index Number',
    info: 'Info',
    inputEndDate: 'End Date',
    inputEndTime: 'End Time',
    inputSelectDays: 'Select days range',
    inputStartDate: 'Start Date',
    inputStartTime: 'Start Time',
    inTown: 'In-Town',
    lastName: 'Last Name',
    lastRatings: 'Last Ratings',
    lastUpdate: 'Last update',
    location: 'Location',
    managerEmail: 'Manager Email',
    manuals: 'Manuals',
    mobilityPlanner: 'Mobility Planner',
    mobilityLogbook: 'Mobility Logbook',
    mobilityLogbookDetails: 'Details Trip Page',
    month: 'Month',
    msgErrorExport: 'Sorry, export with selected filters is not possible',
    myMobility: 'My Mobility',
    name: 'Name',
    no: 'No',
    note: 'Note',
    number: 'Number',
    optional: 'optional',
    outOfTown: 'Out of Town',
    passenger: 'Passenger',
    passengers: 'Passengers',
    pax: 'Pax',
    paxAgency: 'Pax Agency',
    paymentMethod: 'Payment method',
    phoneNumber: 'Phone Number',
    photo: 'Photo',
    price: 'Price',
    purpose: 'Purpose',
    rangeEnd: 'Range End',
    rangeStart: 'Range Start',
    recurring: {
      addRecurring: 'Add recurring drive',
      addRecurringBtn: 'Add Recurring',
      allDays: 'All days',
      cancelRecurringBtn: 'Cancel Recurring',
      conflictMsg1: 'This recurring drive is conflicting with another drive',
      conflictMsg2: 'created for the same time period',
      dailyFrequency: 'Daily frequency',
      dailyRecurringDrive: 'Daily recurring drive',
      drive: 'Recurring drive',
      drivesOverview: 'The following drives will be created',
      forceCreate: {
        adviceMsg:
          'You can proceed with creating this recurring, but afterwards, it is recommended to assign the conflicting drive to another driver.',
        conflictingDrives: 'There are conflicting drives for the recurring period selected',
        continuePrompt:
          'Click YES if you still want to create a recurring drive or click NO to dismiss.',
        title: 'Create Recurring Drive',
      },
      formatRecurringConflict: 'There is a conflict for driver',
      isRecurring: 'Is Recurring',
      onlyWorkingDays: 'Only working days',
      repeatDrive: 'Repeat this drive',
      repeatUntil: 'Until(max 30 days from pick-up date)',
      successMsg: 'Recurring drive was successfully created',
      type: 'Recurring type',
      weeklyRecurringDrive: 'Weekly recurring drive',
      workingDaysFromMonday: 'Only working days (Monday - Friday)',
      workingDaysFromSunday: 'Only working days (Sunday - Thursday)',
    },
    recurringDrive: 'Recurring drive',
    refCode: 'Ref code',
    remarks: 'Remarks',
    reportingArea: 'Reporting Area',
    requestingUnit: 'Requesting Unit',
    rideshared: 'Rideshared',
    sameDay: 'Same day',
    select: 'Select',
    serviceCostSettings: 'Service Cost Settings',
    showAll: 'Show all',
    soft: 'Soft',
    status: 'Status',
    surname: 'Surname',
    to: 'to',
    toIncluded: 'To (included)',
    total: 'Total',
    town: 'Town',
    trainings: 'Trainings',
    transferType: 'Transfer Type',
    transferTypeAirport: 'To/From Airport',
    transferTypeOutTown: 'Out-of-Town',
    transferTypeShuttle: 'Shuttle',
    tripType: 'Trip Type',
    trips: 'Trips',
    tripsEV: 'Nº EV trips',
    tripsDieselPetrol: 'Nº Diesel /Petrol trips',
    typeOfTrip: 'Type of trip',
    unit: 'Unit',
    username: 'Username',
    vehicle: 'Vehicle',
    vehicleTrackingSystem: 'Vehicle Tracking System',
    vendorNumber: 'Vendor Number',
    weekly: 'Weekly',
    when: 'When',
    year: 'Year',
    yes: 'Yes',
  },
  crumbs: {
    bookingDetails: 'Booking Details',
    bookings: 'Bookings',
    bookingList: 'Booking List',
    countryReport: 'Country Report',
    createAgencyInvoice: 'Create Agency Invoice',
    dashboard: 'Dashboard',
    explore: 'Explore',
    facilityMenu: 'Menu',
    home: 'Home',
  },
  feedback: {
    btnConfirmRemark: 'Confirm remark',
    btnForward: 'Forward to admin office',
    comment: 'comment',
    commentAndRemark: 'comment and remark',
    close: 'Close',
    closed: 'Closed',
    confirmOrEditRemark: 'Confirm or edit Remark',
    dateOfRide: 'Date of ride',
    forwardForAction: 'Forward for action',
    inputAdminEmail: 'Admin E-mail address',
    insertRemark: 'Insert Remark',
    msgExportError: 'No feedbacks for this period',
    pageTitle: 'Customer Satisfaction for',
    pending: 'Pending',
    placeholderStatusClosed: 'Write here your remark about the comment…',
    placeholderStatusForwarded: 'Write here your suggestion to admin office',
    placeholderStatusUnderProcess: 'Write here your remark about the comment…',
    processing: 'Processing',
    remarkedBy: 'Remarked by',
    reviewed: 'Reviewed',
    ride: 'ride',
    see: 'See',
    statusChanged: 'Feedback status changed',
    suggestion: 'Suggestion',
    timePeriod: 'Time period',
  },
  invoice: {
    agencyDriver: 'Agency Driver',
    bookedBy: 'Booked by',
    charges: 'Charges',
    comments: 'Comments',
    createInvoice: 'Create Invoice',
    departureTime: 'Departure Time',
    fullTripDetails: 'Full Trip Details',
    greenFundIncluded: 'Green fund included',
    individualInvoiceCreatedTitle: 'The Individual Invoice is created and an email will be sent',
    individualInvoiceFor: 'Individual Invoice for',
    individualInvoiceListTitle: 'Select Individual Invoice',
    inputInvoiceSearchByPaxAgency: 'Search by Pax Agency or Passenger',
    inputSearchByInvoiceNumber: 'Search by Invoice Number',
    insertComment: 'Insert Comment',
    invoiceCreated: 'Invoice created successfully',
    invoiceCreatedModalGreeting: 'You are creating a new invoice',
    invoiceFor: 'Invoice for',
    invoiceHistory: 'Invoice History',
    invoiceListTitle: 'Select booking from location',
    invoiceNumber: 'Invoice Number',
    invoiceTotal: 'Invoice Total',
    issueDate: 'Issue Date',
    msgInvoiceUpdated: 'Invoice was updated',
    msgDriveDeleted: 'Drive was deleted',
    pageTitle: 'Please, fill in the form for the locations',
    passengerDetails: 'Passenger Details',
    paxNumber: 'Pax Number',
    quantity: 'Quantity',
    requestUnit: 'Request Unit',
    sendCopy: 'Send Copy to',
    totalAmount: 'Total amount',
    tripsDetails: 'Trips Details',
    viewInvoice: 'View Invoice',
  },
  logbook: {
    btnFilters: 'Filters',
  },
  mobility: {
    addDriver: 'Add New Driver',
    addVehicle: 'Add New Vehicle',
    bloodGroup: 'Blood Group',
    btnConnected: 'Connected',
    btnConnectToGPS: 'Connect to GPS',
    btnDisconnect: 'Disconnect',
    btnGoToChecklist: 'Go To Checklist',
    btnMarkAvailable: 'Mark as Available',
    btnMarkUnavailable: 'Mark as Unavailable',
    btnSaveSettings: 'Save Settings',
    carInfo: 'Car Info',
    chassisNumber: 'Chassis Number',
    checklistCompletedByDriver: 'From the checklist completed by the driver',
    clear: 'Clear',
    critical: 'Critical',
    criticalIssue: 'Critical Issue',
    dailyChecks: 'Daily checks',
    driverLicense: 'Driver License',
    driversListTitle: 'Drivers for',
    editDriver: 'Edit Driver',
    editVehicle: 'Edit Vehicle',
    expirationDate: 'Expiration Date',
    externalProvider: 'External Provider',
    filterByDriver: 'Filter by Driver',
    filterByPassengerEmail: 'Filter by Passenger Email',
    filterByPassengerLastName: 'Filter by Passenger Last Name',
    filterByVehicle: 'Filter by Vehicle',
    fleetCostsSettings: 'Fleet Costs Settings',
    forVehicle: 'For the vehicle',
    inputCost: 'Cost',
    issues: 'Issues',
    issuesTextVehicleCanStart:
      'The following issues are present but the vehicle can start a new drive',
    issuesTextBeforeStarting:
      'The following issues are present and some of them need to be solved before starting a new drive',
    lastModified: 'Last modified on',
    location: 'Location',
    msgDateError: 'The date is not valid. Refresh the page, please',
    msgDriveCancel: 'Drive was canceled',
    msgDriveCostsUpdated: 'Drive Costs was updated',
    msgDrivePublished: 'Drive was published',
    msgDrivesPublished: 'Drives were published',
    msgDriverUpdated: 'Driver was successfully updated',
    msgShareArmoured: 'Is not possible to share armoured vehicles',
    msgUpdateDrive: 'Drive was updated',
    msgVehicleCreated: 'The vehicle was successfully created',
    msgVehicleUpdated: 'Vehicle was successfully updated',
    mobilityLogbook: 'Mobility Logbook',
    myDrivers: 'My Drivers',
    myVehicles: 'My Vehicles',
    noCostSettings: 'No cost settings',
    notReported: 'Not reported',
    notUsed: 'Not used',
    oneDay: 'One Day',
    otherIssues: 'other issues',
    overviewFor: 'Overview for',
    owned: 'Owned',
    ownedBy: 'Owned by',
    personalInfo: 'Personal Info',
    plateNumber: 'Plate Number',
    preferredDriver: 'Preferred Driver',
    preferredVehicle: 'Preferred Vehicle',
    rented: 'Rented',
    roofNumber: 'Roof Number',
    selectDriver: 'Select Driver',
    selectVehicle: 'Select Vehicle',
    selectWhen: 'Select when',
    selectWhy: 'Select why and when the',
    serviceCostSettingsTitle: 'Trip Cost Multipliers for',
    shared: 'Shared',
    skinType: 'Skin Type',
    statusExistMsg: 'for these dates before making unavailable',
    statusExistWarning: 'is unavailable for these dates',
    submitted: 'Submitted',
    totalKm: 'Total Km',
    unavailable: 'Unavailable',
    vehicleModel: 'Vehicle Model',
    vehicleOwner: 'Vehicle Owner',
    vehicleReasonRegularMaintenance: 'Regular Maintenance',
    vehicleReasonExtraordinaryRepair: 'Extraordinary Repair',
    vehicleReasonForDisposal: 'Unavailable - For disposal',
    vehicleType: 'Vehicle Type',
    vehiclesChecklist: 'Vehicles Checklist',
    vehiclesListTitle: 'Vehicles for',
    willBeDisabled: 'will be disabled',
    willBeUnavailable: 'will be Unavailable',
    willNotBeDisabled: 'cannot be disabled',
    willNotBeUnavailable: 'cannot be Unavailable',
  },
  planner: {
    addLeg: 'Add leg',
    btnReject: 'Reject booking',
    btnTimeSlotEdit: 'Edit time slot',
    commentDriver: 'Comment for Driver',
    commentPax: 'Comment for Passenger',
    createDrive: 'Create Drive',
    dateStart: 'Start date',
    dateEnd: 'End date',
    driveReadOnly: 'Drive (read only)',
    driveScheduledFor: 'Drive scheduled for',
    driveStartedFor: 'Drive was started and stopped for',
    dropOff: 'Drop Off',
    focalPoint: 'Focal Point',
    justReadOnly: 'Just read-only',
    mainInfo: 'Main Info',
    msgCancelDriveConfirm: 'Are you sure you want to cancel drive?',
    msgConflictDrives: 'Selected driver has already drives with another vehicle',
    msgConflictNotInShuttle: 'The changes should be outside the shuttle config',
    msgConflictingDrivesEdit:
      'Please check the dates and time to ensure that are correctly selected for all trips in this booking.',
    msgDriveStatus:
      'Use edit time slot button to edit start time and end time of the trip. However, main info of the passengers cannot be edited',
    msgDriveVerified: 'Press confirm button to proceed with your changes',
    msgNoChanges: 'There are no changes to save',
    msgOverlappingDrives:
      'Submitted legs overlap each other. Please, specify a different date and time',
    msgSharedDrive:
      'There is an existing drive on the planner which conflicts with the entered information. Please, specify different time, driver or vehicle',
    msgShuttleConfigUpdated: 'Shuttle Config was successfully updated',
    multiWarn1: 'Another drive already exists for the same time-slot',
    multiWarn2: 'if you want to assign vehicle and driver for current drive or',
    multiWarn3: 'to dismiss',
    noDriverInformationAvailable: 'No driver information is available',
    noPassengers: 'No passengers',
    numberOfPax: 'Number of Pax',
    pickUp: 'Pick Up',
    planned: 'Planned',
    remarks: 'Remarks (optional)',
    returnTrip: 'Return Trip',
    titleExport: 'Export current month to PDF',
    titleReject: 'By clicking on CONFIRM, the whole booking will be rejected',
    tripInfo: 'Trip Info',
    warnShuttleChange: 'Transfer type will be changed to',
    warnShuttleConflict: 'The selected pick-up/drop-off time is out of the scheduled shuttle time',
    warnShuttleDriverConflict: 'Selected driver is busy with another drive at this time',
    workflow: 'Workflow',
  },
  report: {
    agencyPaxVsNot: 'Agency pax vs not',
    autoFilled: 'Auto-filled. Please edit if required',
    carpooledTrip: 'Carpooled trip',
    co2Saving: 'CO2 savings',
    costRecoverySummary: 'Cost Recovery Summary',
    costRecoveryTitle: 'Report details for',
    countryDemand: 'Country Demand KPIs',
    countryReporting: 'Country Reporting',
    distanceTraveled: 'Distance traveled',
    durationAndDistance: 'Duration and Distance',
    editTripData: 'Edit trip data',
    efficiencySaving: 'Efficiency saving',
    errorMsgDrive: 'No drive with this id found',
    errorMsgDrives: 'No drives found',
    fleet: 'Fleet',
    inputApprovedBy: 'Approved by',
    inputCertifiedBy: 'Certified by',
    inputOdometerEnd: 'Odometer end',
    inputOdometerStart: 'Odometer start',
    inputPreparedBy: 'Prepared by',
    inputSearch: 'Search by email or ref code',
    isDriveInFuture: 'You can only edit past trips',
    isManualEditedTooltip: 'Manually inputted trip data',
    isSyncedTooltip: 'Trip data from VTS',
    monthlyBookings: 'Monthly bookings',
    monthlyCostRecoveries: 'Monthly Cost Recoveries',
    monthlySummary: 'Monthly Summary',
    monthlyTrips: 'Monthly trips',
    monthlyTripsDistance: 'Monthly trips distance',
    monthlyTripsDuration: 'Monthly trips duration',
    noData: 'No data available',
    performedOn: 'Performed on',
    reportDetails: 'Report Details',
    ridesharedTrip: 'Rideshared trip',
    rideSharing: 'Ride Sharing',
    summary: 'Summary',
    totalMileage: 'Total Mileage',
    totalTime: 'Total Time',
    upcomingTripsMsg: 'Upcoming trips or already invoice, trip data not editable',
    workflows: 'Workflows',
  },
  travelRequest: {
    msgSubmitError: 'All required fields should be filled',
    submittedDate: 'Date',
    transportationBooking: 'Transportation Booking',
    tripEditTitle:
      'Please fill in the fields below to update the Security Clearance Request draft of your trip',
  },
};
