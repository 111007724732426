/* istanbul ignore file */
import { Dispatch, FC, SetStateAction, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { format, isBefore, isEqual, parse, parseISO } from 'date-fns';
import { Form, FormApi, FormState, TextArea } from 'informed';
import cn from 'classnames';

import { Button, GoogleMapLink, Modal } from '@components';
import {
  AcceptStatus,
  BookingState,
  DriveState,
  IBookingUpdate,
  PlAcceptDrive,
  PlDrive,
  PlDriver,
  PlResource,
  PlVehicle,
  TransferType,
  VerifiedShuttleType,
} from '@common/interfaces';
import { DateFormat as DF } from '@common/types';
import {
  checkConflictDrives,
  checkDriveIsRideShared,
  checkShuttleConflictRange,
  checkShuttleType,
  getFullDate,
} from '@common/utils';
import {
  CommentField,
  DriverField,
  DropOffDateField,
  DropOffTimeField,
  PickupDateField,
  PickupTimeField,
  VehicleField,
} from './fields';
import '../BookingPanel.styles.scss';

interface AcceptDriveFormProps {
  acceptDrives: PlAcceptDrive[];
  drives: PlDrive[];
  driveIdx: number;
  drivers: PlDriver[];
  rejectBooking: (payload: IBookingUpdate) => Promise<unknown>;
  resource?: [PlResource | null, Dispatch<SetStateAction<PlResource | null>>];
  setAcceptDrives: Dispatch<SetStateAction<PlAcceptDrive[]>>;
  setDate: Dispatch<SetStateAction<Date | null>>;
  showShuttle: boolean;
  vehicles: PlVehicle[];
}

const AcceptDriveForm: FC<AcceptDriveFormProps> = ({
  acceptDrives,
  drives,
  driveIdx,
  drivers,
  rejectBooking,
  resource,
  setAcceptDrives,
  setDate,
  showShuttle,
  vehicles,
}) => {
  const { t } = useTranslation();

  const [acceptState, setAcceptState] = useState<AcceptStatus>(AcceptStatus.Init);
  const [isRideSharedConfirm, setRideSharedConfirm] = useState<boolean>(false);
  const [verifiedShuttle, setVerifiedShuttle] = useState<VerifiedShuttleType | null>(null);

  const data = acceptDrives[driveIdx];
  const isDisabled = acceptState !== AcceptStatus.Init;
  const formRef = useRef<FormApi | null>(null);

  const checkConflicts = () => {
    const formData = formRef.current?.getFormState() as FormState;
    const state = Object.values({ ...(formData.values as DriveState) });
    const { driver, dropoffDate, dropoffTime, pickupDate, pickupTime, vehicle } =
      state[state.length - 1];

    const conflict = checkConflictDrives({
      driver,
      drives,
      dropoffDate,
      dropoffTime,
      pickupDate,
      pickupTime,
      vehicle,
      ...(showShuttle && { transferType: data.transferType }),
    });

    return conflict;
  };

  const onFormSubmit = async () => {
    const conflict = checkConflicts();

    if (conflict) {
      return toast.error(
        `${t('bookingDetails.selected')} ${conflict} ${t('bookingDetails.msgConflictMulti')}`,
      );
    }

    const formData = formRef.current?.getFormState() as FormState;
    const state = Object.values({ ...(formData.values as DriveState) });
    const {
      commentDriver,
      commentPax,
      driver,
      dropoffDate,
      dropoffTime,
      pickupDate,
      pickupTime,
      vehicle,
    } = state[state.length - 1];

    const pickupDateTime = parseISO(
      `${format(pickupDate, DF.ApiDate)}T${format(pickupTime, DF.ApiTime)}`,
    );
    const dropoffDateTime = parseISO(
      `${format(dropoffDate, DF.ApiDate)}T${format(dropoffTime, DF.ApiTime)}`,
    );

    if (!isBefore(pickupDateTime, dropoffDateTime) || isEqual(pickupDateTime, dropoffDateTime)) {
      return toast.error(t('bookingDetails.msgInvalidTime'));
    }

    if (showShuttle && data.transferType) {
      const shuttleConflict = checkShuttleConflictRange({
        driver: driver.value,
        drives,
        end: getFullDate(dropoffDate, dropoffTime),
        start: getFullDate(pickupDate, pickupTime),
        vehicle: vehicle.value,
      });

      if (shuttleConflict === 'driver') return toast.error(t('planner.warnShuttleDriverConflict'));
      if (shuttleConflict === 'time') return toast.error(t('planner.warnShuttleConflict'));
    }

    setAcceptDrives((prevDrive: PlAcceptDrive[]) => {
      const idx = prevDrive.findIndex((drive) => drive.id === acceptDrives[driveIdx].id);
      const currentDrive = prevDrive[idx];
      const currentDriver = drivers.find(({ id }) => id === driver.value);
      const newDriveData = [...prevDrive];

      newDriveData[idx] = {
        ...currentDrive,
        commentDriver,
        commentPax,
        driverAgency: currentDriver?.driverAgency || '',
        driverEmail: currentDriver?.email || '',
        driverFirstName: currentDriver?.name || '',
        driverId: driver.value,
        driverLastName: currentDriver?.lastName || '',
        driverPhone: currentDriver?.phoneNumber || '',
        dropoffDate: format(dropoffDate, DF.ApiDate),
        dropoffTime: format(dropoffTime, DF.ApiTime),
        isHide: false,
        pickupDate: format(pickupDate, DF.ApiDate),
        pickupTime: format(pickupTime, DF.ApiTime),
        vehicleId: vehicle.value,
      };

      return newDriveData;
    });

    setAcceptState(AcceptStatus.Accepting);
    setDate(pickupDate);
  };

  const onBackHandler = async () => {
    setRideSharedConfirm(false);
    setVerifiedShuttle(null);
    setAcceptState(AcceptStatus.Init);
    setAcceptDrives((prevDrive: PlAcceptDrive[]) => {
      const idx = prevDrive.findIndex((drive) => drive.id === acceptDrives[driveIdx].id);
      const currentDrive = prevDrive[idx];
      const newDriveData = [...prevDrive];

      newDriveData[idx] = { ...currentDrive, isHide: true };

      return newDriveData;
    });
  };

  const onConfirmAccepting = async () => {
    setAcceptState(AcceptStatus.Accepted);
    setAcceptDrives((prevDrive: PlAcceptDrive[]) => {
      const idx = prevDrive.findIndex((drive) => drive.id === acceptDrives[driveIdx].id);
      const currentDrive = prevDrive[idx];
      const newDriveData = [...prevDrive];

      newDriveData[idx] = {
        ...currentDrive,
        ...(verifiedShuttle ? { transferType: verifiedShuttle.transferType } : {}),
        ...(verifiedShuttle?.scheduledRouteId
          ? { scheduledRouteId: verifiedShuttle.scheduledRouteId }
          : {}),
        accepted: true,
      };

      return newDriveData;
    });
    setRideSharedConfirm(false);
    setVerifiedShuttle(null);
  };

  return (
    <>
      <Form
        className={cn('form', {
          'form-rejecting': acceptState === AcceptStatus.Rejecting,
          'theme-dark': showShuttle,
        })}
        formApiRef={formRef}
        onSubmit={onFormSubmit}
      >
        {acceptState !== AcceptStatus.Rejecting && (
          <Button
            className="btn btn-booking-reject"
            text={t('planner.btnReject')}
            variant="transparent"
            onClick={() => setAcceptState(AcceptStatus.Rejecting)}
          />
        )}

        <div className="form-drive">
          <div className="form-drive-row">
            <VehicleField
              disabled={isDisabled}
              drivers={drivers}
              leg={data.routeNumber}
              resource={resource}
              value={showShuttle ? data.vehicleId : undefined}
              vehicle={acceptDrives[0]?.vehicleId}
              vehicles={vehicles}
            />
            <DriverField
              disabled={isDisabled}
              driver={acceptDrives[0]?.driverId}
              drivers={drivers}
              leg={data.routeNumber}
              resource={resource}
              value={showShuttle ? data.driverId : undefined}
              vehicles={vehicles}
            />
            <PickupDateField
              acceptDrives={acceptDrives}
              disabled={isDisabled}
              leg={data.routeNumber}
              value={data.pickupDate}
            />
            <PickupTimeField
              acceptDrives={acceptDrives}
              disabled={isDisabled}
              leg={data.routeNumber}
              value={data.pickupTime}
            />
            <DropOffDateField
              acceptDrives={acceptDrives}
              disabled={isDisabled}
              leg={data.routeNumber}
              value={data.dropoffDate}
            />
            <DropOffTimeField
              acceptDrives={acceptDrives}
              disabled={isDisabled}
              leg={data.routeNumber}
              value={data.dropoffTime}
            />
          </div>
          <div className="form-drive-row">
            <CommentField
              disabled={isDisabled}
              label={t('planner.commentPax')}
              placeholder="(optional)"
              name={`${data.routeNumber}.commentPax`}
            />

            <CommentField
              disabled={isDisabled}
              label={t('planner.commentDriver')}
              placeholder="(optional)"
              name={`${data.routeNumber}.commentDriver`}
            />
          </div>
        </div>

        {acceptState !== AcceptStatus.Rejecting && (
          <div className="form-drive-ctrl">
            {acceptState === AcceptStatus.Init && (
              <div className="form-drive-buttons">
                <Button
                  className="btn btn-submit"
                  text={t('bookingDetails.btnShowPreview')}
                  type="submit"
                  variant="submit"
                />

                <GoogleMapLink
                  origins={
                    data.pickupLatitude && data.pickupLongitude
                      ? { lat: data.pickupLatitude, lng: data.pickupLongitude }
                      : null
                  }
                  destinations={
                    data.dropoffLatitude && data.dropoffLongitude
                      ? { lat: data.dropoffLatitude, lng: data.dropoffLongitude }
                      : null
                  }
                />
              </div>
            )}

            {acceptState === AcceptStatus.Accepting && (
              <div className="form-drive-buttons">
                <Button
                  className="btn btn-submit"
                  text={t('bookingDetails.btnConfirmAccept')}
                  variant="submit"
                  onClick={() => {
                    const { transferType } = data;
                    const isRideShared = checkDriveIsRideShared({ data, drives });
                    const verified = checkShuttleType({
                      drives,
                      end: parse(
                        `${data.dropoffDate} ${data.dropoffTime}`,
                        `${DF.ApiDate} ${DF.ApiTime}`,
                        new Date(),
                      ),
                      start: parse(
                        `${data.pickupDate} ${data.pickupTime}`,
                        `${DF.ApiDate} ${DF.ApiTime}`,
                        new Date(),
                      ),
                      transferType: data?.transferType,
                      vehicle: data.vehicleId!,
                    });

                    if (showShuttle && verified && verified?.transferType !== data.transferType) {
                      return setVerifiedShuttle(verified);
                    }

                    if (
                      isRideShared &&
                      (!showShuttle || (showShuttle && transferType !== TransferType.Shuttle))
                    ) {
                      setRideSharedConfirm(true);
                    } else {
                      const conflict = checkConflicts();

                      if (conflict) {
                        onBackHandler();

                        return toast.error(
                          `${t('bookingDetails.selected')} ${conflict} ${t(
                            'bookingDetails.msgConflictMulti',
                          )}`,
                        );
                      }

                      onConfirmAccepting();
                    }
                  }}
                />
                <Button
                  className="btn"
                  text={t('common.btnBack')}
                  variant="transparent"
                  onClick={onBackHandler}
                />
              </div>
            )}
          </div>
        )}
      </Form>

      {acceptState === AcceptStatus.Rejecting && (
        <Form
          className="booking-reject"
          onSubmit={(formState) =>
            rejectBooking({
              reject_comment: formState.values.reason as string,
              state: BookingState.Rejected,
            })
          }
        >
          <h3>{t('planner.titleReject')}</h3>

          <TextArea
            className="field-reason"
            name="reason"
            placeholder="Rejection reason"
            data-testid="planner-drive-form-field-reject-reason"
          />

          <footer>
            <Button
              className="btn btn-cancel"
              text={t('common.btnCancel')}
              variant="outline"
              onClick={() => setAcceptState(AcceptStatus.Init)}
            />

            <Button
              className="btn btn-confirm"
              text={t('common.btnConfirm')}
              variant="filled"
              type="submit"
            />
          </footer>
        </Form>
      )}

      <Modal
        className="modal-planner-confirm"
        showBtnClose
        title={t('planner.createDrive')}
        variant="confirm"
        visible={isRideSharedConfirm || !!verifiedShuttle}
        onClose={onBackHandler}
        onConfirm={onConfirmAccepting}
      >
        {isRideSharedConfirm && (
          <>
            <p>{t('planner.multiWarn1')}.</p>
            <p>
              {t('common.click')} <b>{t('common.yes')}</b> {t('planner.multiWarn2')} <br />
              {t('common.click')} <b>{t('common.no')}</b> {t('planner.multiWarn3')}.
            </p>
          </>
        )}

        {verifiedShuttle && (
          <p>
            {t('planner.warnShuttleChange')}{' '}
            {verifiedShuttle.transferType
              .split('_')
              .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
              .join(' ')}
          </p>
        )}
      </Modal>
    </>
  );
};

export default AcceptDriveForm;
