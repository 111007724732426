/* istanbul ignore file */
import { t } from 'i18next';
import * as yup from 'yup';

const VehicleFormSchema = () =>
  yup.object().shape({
    agency: yup.string().optional(),
    chassisNumber: yup
      .string()
      .max(16, 'Chassis Number cannot be more than 16 characters')
      .required(t('common.fieldRequired')),
    gvlpNumber: yup.string().max(25, 'GVLP Number cannot be more than 16 characters').optional(),
    maxCapacity: yup.number().required(t('common.fieldRequired')),
    plateNumber: yup
      .string()
      .max(10, 'Plate Number cannot be more than 10 characters')
      .required(t('common.fieldRequired')),
    preferredDriver: yup.string().nullable().optional(),
    protection: yup.string().required(t('common.fieldRequired')),
    roofNumber: yup
      .string()
      .nullable()
      .max(16, 'Roof Number cannot be more than 16 characters')
      .optional(),
    sharedLocations: yup.array().of(yup.string()).optional(),
    vehicleModel: yup.string().required(t('common.fieldRequired')),
    vehicleOwner: yup.string().optional(),
  });

export default VehicleFormSchema;
