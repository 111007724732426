/* istanbul ignore file */
import { t } from 'i18next';
import * as yup from 'yup';

const DriverFormSchema = () =>
  yup.object().shape({
    bloodGroup: yup.string().nullable().optional(),
    email: yup
      .string()
      .email('Please enter a valid email address')
      .required(t('common.fieldRequired')),
    name: yup.string().required(t('common.fieldRequired')),
    lastName: yup.string().required(t('common.fieldRequired')),
    licenseExpirationDate: yup.date().nullable().optional(),
    licenseNumber: yup
      .string()
      .nullable()
      .max(12, 'License number cannot be more than 12 characters')
      .optional(),
    phoneNumber: yup.string().required(t('common.fieldRequired')),
    preferredVehicle: yup.string().nullable().optional(),
    sharedLocations: yup.array().of(yup.string()).optional(),
  });

export default DriverFormSchema;
