import { useEffect } from 'react';
import { useFormApi } from 'informed';
import { addDays, addMinutes, isValid, set } from 'date-fns';

import type { GoogleSuggestion } from '@common/types';
import { FeatureFlags, DriveFormSteps as Steps } from '@common/interfaces';
import { LocationField } from '@components';
import { fetchDistanceMatrix } from '@common/utils';
import { useFieldState } from '@common/hooks';
import { useGlobalContext } from '@context';

export type FieldProps = {
  city: string;
  country: string;
  fieldGroup: string;
  idx: number;
  isMultiLeg?: boolean;
  isReturnTrip?: boolean;
  label?: string;
  name: string;
  required?: boolean;
  onChange?: (e: GoogleSuggestion | string) => void;
};

const GoogleLocationField = ({
  city,
  country,
  fieldGroup,
  idx,
  isMultiLeg = false,
  isReturnTrip = false,
  label,
  name,
  required,
  onChange,
}: FieldProps) => {
  const { featureFlags } = useGlobalContext();
  const { getFormState, setValue } = useFormApi();
  const formState = getFormState().values as any;
  const dropoffDate = fieldGroup ? `${fieldGroup}.dropoffDate` : 'dropoffDate';
  const dropoffTime = fieldGroup ? `${fieldGroup}.dropoffTime` : 'dropoffTime';
  const dropoffLoc = fieldGroup ? `${fieldGroup}.dropoffLocation` : 'dropoffLocation';
  const pickupLoc = fieldGroup ? `${fieldGroup}.pickupLocation` : 'pickupLocation';
  const { value: pickupDate } = useFieldState<Date>('pickupDate');
  const { value: pickupTime } = useFieldState<Date>('pickupTime');

  const tripKey = isReturnTrip ? Steps.ReturnTrip : Steps.Trip;
  const drives = formState?.[tripKey]?.[isMultiLeg ? 'driveListMultileg' : 'driveList'];
  const { dropoffLat, dropoffLng, dropoffLocation, pickupLat, pickupLng, pickupLocation } =
    drives?.[idx!] || {};

  const handleLocationChange = (e: GoogleSuggestion | string) => {
    onChange?.(e);

    if (dropoffLat && dropoffLng && pickupLat && pickupLng) {
      (async () => {
        if (!dropoffLat || !dropoffLng || !pickupLat || !pickupLng) return;

        const estimated = await fetchDistanceMatrix({
          origins: [{ lat: pickupLat, lng: pickupLng }],
          destinations: [{ lat: dropoffLat, lng: dropoffLng }],
          debugging: (featureFlags as FeatureFlags)?.flags?.debuggingGoogleMap?.is_active,
        });

        if (estimated && isValid(pickupDate) && isValid(pickupTime)) {
          const hours = +Math.floor(estimated.duration / 3600)
            .toString()
            .padStart(2, '0');
          const minutes = +Math.ceil((estimated.duration % 3600) / 60)
            .toString()
            .padStart(2, '0');

          const combinedPickup = set(pickupDate, {
            hours: pickupTime.getHours(),
            minutes: pickupTime.getMinutes(),
            seconds: 0,
          });

          const totalMinutes = hours * 60 + minutes;
          const totalDays = Math.floor(totalMinutes / (24 * 60));
          const minRemaining = totalMinutes % (24 * 60);
          const calcDropoffDate = addMinutes(addDays(combinedPickup, totalDays), minRemaining);
          const formattedDate = set(calcDropoffDate, { hours: 0, minutes: 0, seconds: 0 });

          setValue(dropoffDate, formattedDate);
          setValue(dropoffTime, calcDropoffDate);
          setValue(fieldGroup ? `${fieldGroup}.estimated` : 'estimated', estimated);
        }
      })();
    }
  };

  useEffect(() => {
    if (dropoffLocation && typeof dropoffLocation === 'object') setValue(dropoffLoc, ' ');
    if (pickupLocation && typeof pickupLocation === 'object') setValue(pickupLoc, ' ');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropoffLocation, pickupLocation]);

  return (
    <LocationField
      city={city}
      country={country}
      label={label}
      name={name}
      required={required}
      onChange={handleLocationChange}
    />
  );
};

export default GoogleLocationField;
