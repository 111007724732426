import type { EventDropArg } from '@fullcalendar/react';
import { useTranslation } from 'react-i18next';
import { format, isAfter, isBefore, isEqual, parse } from 'date-fns';

import { Button, Dialog } from '@components';
import { ArrowRight } from '@assets/svg/icons';
import { DateFormat as DF } from '@common/types';
import { PlDrive, ResViewType } from '@common/interfaces';
import { checkShuttleWithinRange } from '@pages/MobilityPlannerPage/utils';
import './EventDropPreview.styles.scss';

interface DrivePreviewProps {
  data: EventDropArg | null;
  drives: PlDrive[];
  resourceType: ResViewType;
  onCancel: () => void;
  onEdit: () => void;
  onSubmit: () => void;
}

const EventDropPreview = ({
  data,
  drives,
  resourceType,
  onCancel,
  onEdit,
  onSubmit,
}: DrivePreviewProps) => {
  const { t } = useTranslation();

  if (!data) return null;

  const { event, newResource, oldEvent, oldResource } = data;
  const { title: newLabel, extendedProps: newResourceProps } = newResource || {};
  const { title: oldLabel, extendedProps: oldResourceProps } = oldResource || {};
  const { end, extendedProps, start } = event || {};
  const newResourceLabel = newResourceProps?.vehicleId ? newLabel?.split('|') : newLabel;
  const oldResourceLabel = oldResourceProps?.vehicleId ? oldLabel?.split('|') : oldLabel;

  const currentResKey = resourceType === ResViewType.Vehicle ? 'vehicleId' : 'driverId';
  const crossResKey = resourceType === ResViewType.Vehicle ? 'driverId' : 'vehicleId';
  const isEventSharedDrive =
    event.extendedProps[crossResKey] !== oldEvent.extendedProps[crossResKey];

  const hasShuttleWithinRange = checkShuttleWithinRange({
    drives: drives.filter((d) => d.shuttleConfig),
    start: start!,
    end: end!,
    resourceId: newResource?.id!,
    resourceType: newResourceProps?.vehicleId ? ResViewType.Vehicle : ResViewType.Driver,
  });

  const hasConflictedResources = drives
    .filter((i: PlDrive) => i.id !== event.id)
    .some((drive: PlDrive) => {
      const isDifferentResource = drive[currentResKey] !== event.extendedProps[currentResKey];
      const isSameCrossResource = drive[crossResKey] === event.extendedProps[crossResKey];

      const driveStart = parse(drive.pickupTime, DF.ApiTimeFull, start!);
      const driveEnd = parse(drive.dropoffTime, DF.ApiTimeFull, end!);

      const isTimeOverlapping =
        (isBefore(start!, driveEnd) && isAfter(end!, driveStart)) ||
        isEqual(start!, driveStart) ||
        isEqual(end!, driveEnd);

      return isDifferentResource && isSameCrossResource && isTimeOverlapping;
    });

  const isConfirmBtn =
    !hasConflictedResources && !extendedProps?.scheduledRouteId && !hasShuttleWithinRange;

  return (
    <Dialog
      className="dialog-event-drop-preview"
      opened={!!data}
      position={{ bottom: 35, right: 25 }}
      onClose={onCancel}
    >
      <header>
        <h2>Event will be changed</h2>
      </header>

      <ul className="event-drop-preview">
        {!isEqual(oldEvent?.start as Date, event?.start as Date) && (
          <>
            <li>
              <div>{t('bookingDetails.pickupTime')}:</div>
              <span>{format(oldEvent?.start as Date, DF.ApiTime)}</span>
              <ArrowRight />
              <span>{format(event?.start as Date, DF.ApiTime)}</span>
            </li>
            <li>
              <div>{t('bookingDetails.dropoffTime')}:</div>
              <span>{format(oldEvent?.end as Date, DF.ApiTime)}</span>
              <ArrowRight />
              <span>{format(event?.end as Date, DF.ApiTime)}</span>
            </li>
          </>
        )}

        {oldResourceProps && (
          <li>
            <div>{oldResourceProps?.vehicleId ? t('common.vehicle') : t('common.driver')}:</div>
            {Array.isArray(oldResourceLabel) ? (
              <p>
                <span>{oldResourceLabel?.[0]}</span>
                <span>{oldResourceLabel?.[1]}</span>
              </p>
            ) : (
              <span>{oldResourceLabel}</span>
            )}
            <p>
              <ArrowRight />
            </p>
            {Array.isArray(newResourceLabel) ? (
              <p>
                <span>{newResourceLabel?.[0]}</span>
                <span>{newResourceLabel?.[1]}</span>
              </p>
            ) : (
              <span>{newResourceLabel}</span>
            )}
          </li>
        )}

        {isEventSharedDrive && (
          <li>
            {oldResourceProps?.vehicleId ? (
              <>
                <div>{t('common.driver')}: </div>
                <p>
                  <span>
                    {oldEvent.extendedProps.driverFirstName} {oldEvent.extendedProps.driverLastName}
                  </span>
                </p>
                <p>
                  <ArrowRight />
                </p>
                <p>
                  <span>
                    {event.extendedProps.driverFirstName} {event.extendedProps.driverLastName}
                  </span>
                </p>
              </>
            ) : (
              <>
                <div>{t('common.vehicle')}: </div>
                <p>
                  <span>{oldEvent.extendedProps.vehicleLabel}</span>
                </p>
                <p>
                  <ArrowRight />
                </p>
                <p>
                  <span>{event.extendedProps.vehicleLabel}</span>
                </p>
              </>
            )}
          </li>
        )}
      </ul>

      <footer>
        <Button
          className="btn btn-revoke"
          text={t('common.btnRevoke')}
          variant="default"
          onClick={onCancel}
        />
        <Button
          className="btn btn-edit"
          text={t('common.btnConfirmAndEdit')}
          variant="default"
          onClick={onEdit}
        />
        {isConfirmBtn && (
          <Button
            className="btn btn-confirm"
            text={t('common.btnConfirm')}
            variant="filled"
            onClick={onSubmit}
          />
        )}
      </footer>
    </Dialog>
  );
};

export default EventDropPreview;
