import { type FC, Fragment, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { format, isBefore, parse } from 'date-fns';
import { enUS, fr } from 'date-fns/locale';

import { Button, DetailsContainer } from '@unbooking/ui-modules';
import type {
  BookingDetails,
  IBookingUpdate,
  BDRoute,
  BookingAttachments,
} from '@common/interfaces';
import { DriveStatus, BookingState as BState, BookingTripType } from '@common/interfaces';
import { GoogleMapLink, Loader, PageTitle } from '@components';
import { useRepository } from '@context';
import { useFacility } from '@common/hooks';
import { DateFormat as DF, StatusCode } from '@common/types';
import { formatDateString, Serializer } from '@common/utils';
import { ArrowRound, File } from '@assets/svg/icons';
import './styles.scss';

const sliceIntoChunks = (arr: any[], size: number): any[] =>
  arr.reduce((res, _, i) => (i % size === 0 ? [...res, arr.slice(i, i + size)] : res), []);

const sortRoutes = (a: BDRoute, b: BDRoute) => {
  const firstRoute = parse(`${a.pickupDate} ${a.pickupTime}`, DF.ApiDateFull, new Date());
  const secondRoute = parse(`${b.pickupDate} ${b.pickupTime}`, DF.ApiDateFull, new Date());
  return isBefore(firstRoute, secondRoute) ? -1 : 1;
};

const BookingDetailsPage: FC = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { id: bookingId } = useParams<{ id: string }>();
  const { bookingRepository } = useRepository();
  const { agencyName, facility, facilityId } = useFacility();
  const { city, country } = facility;
  const locale = i18n.language === 'fr' ? fr : enUS;

  const [data, setData] = useState<BookingDetails>();
  const [isOpenRequestorPanel, setIsOpenRequestorPanel] = useState<boolean>(false);
  const [isOpenOtherPassengersPanel, setIsOpenOtherPassengersPanel] = useState<boolean>(false);

  const {
    agencyFk,
    attachments,
    dispatchDate,
    drives,
    id,
    passengersInfo,
    recurringData,
    routes,
    state,
    stateDisplay,
    typeOfTripDisplay,
  } = data || {};

  const { isLoading, refetch: getBookingDetails } = useQuery(
    `get-booking-details-${bookingId}`,
    () => bookingRepository.getBookingDetails(facilityId, bookingId!),
    {
      retry: false,
      onSuccess: (booking) => booking && setData(Serializer.formatBookingDetails(booking)),
      onError: (error: any) => {
        if (error?.response?.status === StatusCode.ClientErrorNotFound) {
          toast.error(t('bookingDetails.errorMsg'));
          setTimeout(() => {
            navigate(-1);
          }, 2000);
        }
      },
    },
  );

  const { mutateAsync: updateBooking, isLoading: isBookingProcessing } = useMutation(
    'patch-booking-update',
    (payload: IBookingUpdate) => bookingRepository.updateBooking(facilityId, id!, payload),
    {
      onSuccess: () => {
        toast.success(t('bookingDetails.msgUpdateBooking'));
        getBookingDetails();
      },
      onError: (e: AxiosError) => {
        if (e.message) toast.error(t('common.errorMsgDefault'));
      },
    },
  );

  const getBookingStateLabel = (): JSX.Element | null =>
    state ? (
      <div className={`booking-label btn-${state?.replace('_', '-')}`}>
        {`${t('common.booking')} ${stateDisplay}`}
      </div>
    ) : null;

  const isBookingEditable =
    state === BState.Accepted ||
    (state === BState.Ongoing && drives?.find((i) => i.state === DriveStatus.NotStarted));
  const isMultiLeg = data?.typeOfTrip === BookingTripType.MultiLeg;
  const isNotProcessed = data?.state === BState.NotProcessed;

  const detailsItems = [
    { label: t('booking.refCode'), content: data?.refCode ?? '', className: 'row-1-col-1' },
    { label: t('booking.bookingRequest'), content: dispatchDate ?? '', className: 'row-1-col-2' },
    { label: t('common.firstName'), content: data?.firstName ?? '', className: 'row-1-col-3' },
    { label: t('common.lastName'), content: data?.lastName ?? '', className: 'row-1-col-4' },
    { label: t('common.pax'), content: String(data?.pax ?? ''), className: 'row-2-col-1' },
    { label: t('common.agency'), content: agencyFk?.shortName ?? '', className: 'row-2-col-2' },
    { label: t('common.phoneNumber'), content: data?.phoneNumber ?? '', className: 'row-2-col-3' },
    { label: t('common.email'), content: data?.email ?? '', className: 'row-2-col-4' },
    { label: t('common.indexNumber'), content: data?.indexNumber ?? '', className: 'row-3-col-1' },
    {
      label: t('common.requestingUnit'),
      content: data?.requestingUnitDisplay ?? '',
      className: 'row-3-col-2',
    },
    { label: t('common.typeOfTrip'), content: typeOfTripDisplay ?? '', className: 'row-3-col-3' },
    {
      label: t('common.transferType'),
      content: data?.transferTypeDisplay ?? '',
      className: 'row-3-col-4',
    },
    ...(data?.transferType === 'airport'
      ? [
          {
            label: t('common.flightArrivalDepartureTime'),
            content: data?.flightArrivalDepartureTime
              ? formatDateString(data?.flightArrivalDepartureTime, DF.ApiTimeFull, DF.ApiTime)
              : '',
            className: 'row-4-col-1',
          },
          {
            label: t('common.flightNumber'),
            content: data?.flightNumber ?? '',
            className: 'row-4-col-2',
          },
        ]
      : []),
    ...(data?.remarks
      ? [
          {
            label: t('common.remarks'),
            content: data?.remarks,
            className: 'row-5-col-1 line-break',
          },
        ]
      : []),
  ];

  const recurringItems = recurringData && [
    { label: t('common.recurring.isRecurring'), content: t('common.yes') },
    {
      label: t('common.recurring.type'),
      content: `${recurringData.frequencyDisplay} ${
        recurringData.frequencyDisplay === 'Daily' ? `(${recurringData.weekdaysDisplay})` : ''
      }`,
    },
    {
      label: t('common.inputStartDate'),
      content: format(new Date(recurringData.startDate), DF.BookingInfoDate, { locale }),
    },
    {
      label: t('common.inputEndDate'),
      content: format(new Date(recurringData.endDate), DF.BookingInfoDate, { locale }),
    },
  ];

  const requestorItems = [
    { label: t('common.username'), content: data?.bookingAgentUsername ?? '', className: 'col-1' },
    { label: t('common.email'), content: data?.bookingAgentEmail ?? '', className: 'col-2' },
    {
      label: t('common.firstName'),
      content: data?.bookingAgentFirstName ?? '',
      className: 'col-3',
    },
    { label: t('common.lastName'), content: data?.bookingAgentLastName ?? '', className: 'col-4' },
  ];

  const renderRouteDetails = (route: BDRoute, idx: number) => {
    const formatDate = (date: string) => formatDateString(date, 'yyyy-MM-dd', 'dd MMM yyyy') || '';
    const tr = (key: string) => t(`bookingDetails.${key}`);

    const {
      dropoffDate,
      dropoffLocation,
      dropoffTime,
      dropoffTown,
      pickupLocation,
      pickupTime,
      pickupTown,
    } = route;

    const items = [
      { label: tr('pickupDate'), content: formatDate(route.pickupDate), className: 'row-1-col-1' },
      { label: tr('pickupTime'), content: pickupTime, className: 'row-1-col-2' },
      { label: tr('dropoffDate'), content: formatDate(dropoffDate), className: 'row-1-col-3' },
      {
        label: tr('dropoffTime'),
        content: dropoffTime && data?.state !== BState.NotProcessed ? dropoffTime : '',
        className: 'row-1-col-4',
      },
      { label: tr('pickupTown'), content: pickupTown || '', className: 'row-2-col-1' },
      { label: tr('pickupLocation'), content: pickupLocation || '', className: 'row-2-col-2' },
      { label: tr('dropoffTown'), content: dropoffTown || '', className: 'row-3-col-1' },
      { label: tr('dropoffLocation'), content: dropoffLocation || '', className: 'row-3-col-2' },
      ...(!isNotProcessed
        ? [
            {
              label: t('common.driver'),
              content: data?.drives[idx]?.driver || '',
              className: 'row-4-col-1',
            },
            {
              label: t('mobility.plateNumber'),
              content: data?.drives[idx]?.vehicle || '',
              className: 'row-4-col-2',
            },
          ]
        : []),
    ];

    const routeClass = route.isActive ? '' : 'route-canceled';
    const title = isMultiLeg
      ? `${tr('leg')} ${idx + 1}`
      : `${idx === 0 ? tr('outwardTrip') : tr('returnTrip')}`;

    const rejected = isNotProcessed && route.state === BState.Rejected;

    return (
      <div className="booking-route" key={route.id}>
        {rejected && (
          <div className="prefix">
            {`[${t('booking.rejected')}] `}
            {route.rejectComment ? `Reason: ${route.rejectComment}` : ''}
          </div>
        )}
        <DetailsContainer className={`route-details ${routeClass}`} title={title} items={items} />
        <GoogleMapLink
          origins={
            route.pickupLatitude && route.pickupLongitude
              ? { lat: route.pickupLatitude, lng: route.pickupLongitude }
              : null
          }
          destinations={
            route.dropoffLatitude && route.dropoffLongitude
              ? { lat: route.dropoffLatitude, lng: route.dropoffLongitude }
              : null
          }
        />
      </div>
    );
  };

  return (
    <>
      <PageTitle
        className="booking-details-title"
        title={city ? `${t('bookingDetails.title')}, ${city}, ${country} | ${agencyName}` : ''}
      />

      <Loader spinning={isLoading || isBookingProcessing}>
        <section className="hbh-container booking-details-container">
          <div className="title">{getBookingStateLabel()}</div>

          <DetailsContainer className="asset-details" divider items={detailsItems} />

          {recurringData && (
            <DetailsContainer
              className="recurring-drive"
              items={recurringItems ?? []}
              title={t('common.recurring.drive')}
            />
          )}

          {routes?.sort(sortRoutes).map(renderRouteDetails)}

          <div className="booking-requestor-details">
            <div className="details-panel">
              <span>{t('bookingDetails.showBookingRequestorDetails')}</span>
              <Button
                className={isOpenRequestorPanel ? 'btn-requestor open' : 'btn-requestor'}
                icon={<ArrowRound />}
                variant="icon"
                onClick={() => setIsOpenRequestorPanel(!isOpenRequestorPanel)}
              />
            </div>

            <div className={isOpenRequestorPanel ? 'panel-content open' : 'panel-content'}>
              <DetailsContainer
                className="asset-requestor"
                items={requestorItems}
                title={t('bookingDetails.bookingRequestor')}
              />
            </div>
          </div>

          {!!passengersInfo?.length && (
            <div className="booking-passengers-details">
              <div className="details-panel">
                <span>Show other passengers details</span>
                <Button
                  className={isOpenOtherPassengersPanel ? 'btn-requestor open' : 'btn-requestor'}
                  data-testid="btn-show-other-passengers"
                  icon={<ArrowRound />}
                  variant="icon"
                  onClick={() => setIsOpenOtherPassengersPanel(!isOpenOtherPassengersPanel)}
                />
              </div>

              <div className={isOpenOtherPassengersPanel ? 'panel-content open' : 'panel-content'}>
                {passengersInfo.map((pax, idx: number) => (
                  <Fragment key={pax.email}>
                    <DetailsContainer
                      title={`Passenger ${idx + 2}`}
                      items={[
                        { label: 'First Name', content: pax.firstName },
                        { label: 'Last Name', content: pax.lastName },
                        { label: 'Email', content: pax.email },
                      ]}
                    />

                    <DetailsContainer
                      items={[
                        { label: 'Agency', content: pax.agency },
                        { label: 'Phone number', content: pax.phoneNumber },
                        { label: 'Requesting unit', content: pax.requestingUnitDisplay },
                      ]}
                    />
                  </Fragment>
                ))}
              </div>
            </div>
          )}

          {!!attachments?.length &&
            sliceIntoChunks(attachments, 2).map((chunk, i) => (
              <DetailsContainer
                title={i === 0 ? 'Attachments' : undefined}
                className="attachments"
                items={chunk.map((attachment: BookingAttachments, j: number) => ({
                  label: '',
                  content: (
                    <a className="attachment" href={attachment.attachmentLink}>
                      <File />
                      <h5>Attachment {i * 2 + j + 1}</h5>
                    </a>
                  ),
                }))}
              />
            ))}

          <div className="footer">
            {getBookingStateLabel()}

            <div className="buttons">
              {isBookingEditable && (
                <Button
                  className="btn btn-edit"
                  text={t('common.btnEdit')}
                  variant="primary"
                  onClick={() => navigate(`edit-drives/${routes?.[0].driveId}`)}
                />
              )}

              {data && state === BState.Accepted && (
                <Button
                  className="btn btn-cancel"
                  text={t('common.btnCancel')}
                  variant="danger"
                  onClick={() => !isBookingProcessing && updateBooking({ state: BState.Cancelled })}
                />
              )}

              <Button
                className="btn btn-back"
                text={t('common.btnBackToList')}
                variant="primary"
                onClick={() => navigate(-1)}
              />
            </div>
          </div>
        </section>
      </Loader>
    </>
  );
};

export default BookingDetailsPage;
